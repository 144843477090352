<template>
  <div id="Page-Referendum-Content">
		Page-Referendum-Content
  </div>
</template>

<script>
export default {
  name: 'Page-Referendum-Content',
  components: {
    
  },
  data(){
    return {
			
    };
  },
  async mounted () {
	
  },
  methods: {
		
  }
}
</script>

<style scoped lang="less">
	
</style>